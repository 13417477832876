import { Box } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import Image from 'next/image';
import { FunctionComponent } from 'react';

import { Breadcrumb } from './Breadcrumb';
import { DATA_HOOKS } from '../../dataHooks';

import css from './index.module.scss';

export type BreadcrumbsProps = {
  breadcrumbItems: BreadcrumbItem[];
  shallow?: boolean;
  children?: JSX.Element;
  sticky?: boolean;
  ref?: any;
  hideHomePage?: boolean;
  onCategoryMenuClick?: () => void;
  onHomePageClick?: (text: string) => Promise<void>;
  navCategoryTree?: JSX.Element;
};

export type BreadcrumbItem = {
  label: string;
  id?: string;
  uri?: string;
  onClick?: () => Promise<void>;
};

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbItems,
  shallow,
  children,
  onCategoryMenuClick,
  onHomePageClick,
  ref,
  hideHomePage,
  navCategoryTree,
}: BreadcrumbsProps) => {
  const { t } = useTranslation();

  const onMenuKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && onCategoryMenuClick) {
      onCategoryMenuClick();
    }
  };

  return (
    <Box
      verticalAlign='middle'
      dataHook={DATA_HOOKS.BREADCRUMBS}
      ref={ref}
      className={css.wrapper}
    >
      <Box
        direction='vertical'
        alignContent={'center'}
        verticalAlign='middle'
        className={css.menu}
      >
        {onCategoryMenuClick && navCategoryTree && (
          <>
            <div
              className={css.menuButton}
              onClick={onCategoryMenuClick}
              onKeyDown={onMenuKeyDown}
              tabIndex={0}
              data-hook={DATA_HOOKS.BREADCRUMB_CATEGORY_MENU}
            >
              <Image
                src={'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/icons/breadcrumbs.svg'}
                width='24'
                height='24'
                className={css.menuButtonImg}
                alt=''
                loading='eager'
              />
            </div>
            {navCategoryTree}
          </>
        )}
      </Box>
      <div className={css.scrollableIndicator} />
      <ol
        tabIndex={0}
        className={classNames(css.breadcrumbs, {
          [css.withMenu]: onCategoryMenuClick,
        })}
        itemScope
        itemType='https://schema.org/BreadcrumbList'
      >
        {!hideHomePage && <Box alignContent={'top'} key={'home'} className={css.breadcrumbItem}>
          <Breadcrumb
            hideChevron
            breadcrumbItem={{
              label: t('breadcrumbs.home'),
              uri: '/',
              onClick: async () => onHomePageClick?.(t('breadcrumbs.home')),
            }}
            isLastItem={false}
            index={1}
          />
        </Box>
        }
        {breadcrumbItems.map((breadcrumbItem, index) => {
          const isLastItem = index === breadcrumbItems.length - 1;
          return (
            <Breadcrumb
              key={breadcrumbItem.id || index}
              breadcrumbItem={breadcrumbItem}
              isLastItem={isLastItem}
              index={index + (hideHomePage ? 1 : 2)}
              shallow={shallow}
              hideChevron={index === 0 && hideHomePage}
            />
          );
        })}
      </ol>
      {children}
    </Box>
  );
};
